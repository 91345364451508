@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500&display=swap");

:root {
  --border-color: none !important;
  --foreground-rgb: 0, 0, 0;
  --accent-color: #33192D;
  --link-color: #0076BD;
  --primary-color: #FFFFFF;
  --secondary-color: #eadbc4;
  --custom-input-border-color: #E6E6E6;
  --inactive-icon-color: #BBBBBB;
  --alert-color: #E10000;
  --search-input-placeholder-color: var(--secondary-color) !important;
  --highlight-color: var(--link-color) !important;
  --variant-solidBg: var(--accent-color) !important;
  --variant-solidHoverBg: #33192DBE !important;
  --variant-solidActiveBg: var(--accent-color) !important;
  --joy-palette-primary-solidHoverBg: #33192DBE !important;
  --joy-palette-primary-solidBg: var(--accent-color) !important;
  --joy-palette-primary-500: var(--accent-color) !important;
  --joy-palette-focusVisible: var(--accent-color) !important;
  --joy-palette-primary-solidActiveBg: var(--accent-color) !important;
  --Slider-thumbColor: var(--link-color) !important;
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: var(--primary-color);
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  overflow: auto !important;
}

.body-style {
  color: rgb(var(--foreground-rgb));
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
}

.redhat-display {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
}

a {
  color: var(--link-color);
}

a:hover {
  text-decoration: underline;
}

input {
	font-size: 0.8rem;
	padding-left: 10px;
  border: 1px solid var(--custom-input-border-color);
	width: 200px;
	border-radius: 7px;
	height: 30px;
}

.navbar {
	background-color: var(--accent-color);
}

a.white-link {
	color: white;
}

a:hover.white-link  {
	text-decoration: underline;
}

.normal-button {
  background-color: var(--link-color);
  color: #FFFFFF;
  border-radius: 10px;
  min-width: 150px;
  font-size: 1.0rem;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  z-index: 1000;
  padding: 10px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  font-size: 14px !important;
}

.no-shadow {
  box-shadow: none !important;
}

.small-button {
  background-color: var(--accent-color);
  color: #FFFFFF;
  right: 0;
  border: 2px solid white;
  border-radius: 10px;
  width: 120px;
  bottom: 2rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
}

.shadow-box {
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.071);
  position: relative;
  background-color: white;
}

.item-image {
  border: 3px solid var(--accent-color);
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.071);
}

.mobile-item-list-image {
  border: 0px solid var(--accent-color);
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.071);
}

.item-image-bid-info {
  border: 3px solid var(--accent-color);
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.071);
}

.item-image-map {
  border: 2px solid var(--accent-color);
  box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.071);
}

.show-more-button {
  position: relative;
  left: 100px;
  top: -250px;
}

.show-more-button-bids {
  position: relative;
  left: 100px;
  top: -200px;
}

.zoom-in-hover {
  cursor: pointer;
  transition: transform 250ms;
}

.zoom-in-hover:hover {
  transform: scale(1.06);
}

input.checkbox {
  width: 16px;
  height: 16px;
  accent-color: var(--link-color);
}

.larger-input {
  height: 33px;
}

.link-button {
  cursor: pointer;
  border: 0;
}

.dropZoneOverlay, .FileUpload {
  width: 100%;
  height: 30px; 
  cursor: pointer;
}

.dropZoneOverlay {
  text-align: center;
  position: relative;
  top: -30px;
  text-decoration: none;
  color: var(--link-color);
}

.dropZoneOverlay:hover {
  text-decoration: underline;
}

.FileUpload {
  opacity: 0;
  position: relative;
  z-index: 1;
}

.avatar-img {
  border: 5px solid var(--accent-color);
  scale: 0.8;
  top: 105px;
  left: -215px;
}

.avatar-img-bidder {
  border: 5px solid var(--accent-color);
  scale: 0.8;
  top: 0px;
  left: -220px;
}

.avatar-img-bid-info {
  border: 4px solid var(--accent-color);
  scale: 0.6;
  top: -165px;
  left: -15px;
}

.avatar-img-bid-page {
  border: 4px solid var(--accent-color);
  scale: 0.6;
  position: relative;
  top: -165px;
  left: -40px;
}

.avatar-img-account {
  border: 5px solid var(--accent-color);
  scale: 0.8;
  position: relative;
  top: -20px;
  left: 40px;
}

.avatar-img-account-mobile {
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, 0.071);
  border: 3px solid var(--accent-color);
  scale: 0.75;
}

.avatar-img-account-page {
  border: 2px solid var(--accent-color);
}

.avatar-img-account-bid {
  border: 1px solid var(--accent-color);
}

.avatar-trade-img1 {
  border: 5px solid var(--accent-color);
  scale: 0.7;
  top: 0px;
  left: -223px;
}

.avatar-trade-img2 {
  border: 5px solid var(--accent-color);
  scale: 0.7;
  top: 20px;
  left: -318px;
}

.bid-amount {
  font-size: 18px;
  color: var(--link-color);
}

.bid-amount-map {
  font-size: 14px;
  color: var(--link-color);
}

.search-amount {
  font-size: 20px;
  font-weight: bold;
  color: var(--link-color);
}

.bid-item-overlay {
  width: calc(100% - 16px);
}

.bid-item-overlay-bid-info {
  width: calc(100% - 112px);
}

.bid-item-overlay-mobile-transaction {
  width: 100%;
}

.offer-hover:hover {
  z-index: 100;
}

.offer-type {
  color: var(--accent-color);
  text-decoration: underline;
  text-align: right;
}

.bid-hover:hover {
  z-index: 100;
}

.auction-border {
  border: 3px solid;
  border-color: var(--link-color);
}

.auction-time {
  font-size: 20px;
  font-weight: bold;
  color: var(--link-color);
}

.auction-time-mobile {
  font-size: 16px;
  font-weight: bold;
  color: var(--link-color);
}

.search-border {
  border: 3px solid var(--accent-color);
}

.search-border-map {
  border: 3px solid var(--accent-color);
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.full-screen-border-map {
  border-top: 3px solid var(--accent-color);
  border-bottom: 3px solid var(--accent-color);
  border-right: 3px solid var(--accent-color);
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.search-line {
  background-color: var(--accent-color);
  opacity: 0.2;
}

.search-line-mobile {
  background-color: var(--accent-color);
  opacity: 0.1;
}

radio {
  border: 0 !important;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  accent-color: var(--link-color) !important;
}

.modal-confirm {
  border-radius: 5px !important;
  background-color: var(--accent-color) !important;
  color: white !important;
}

.offer-price {
  font-weight: bold;
  color: var(--link-color);
}

.chat-message-right {
  background-color: var(--accent-color);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  font-size: 0.9rem;
  margin-left: auto;
}

.text-balloon-triangle-right {
  width: 0;
  height: 0;
  position: relative;
  left: 150px;
  top: 0px;
  margin-bottom: -20px;
  border-style: solid;
  border-width: 0 40px 20px 0;
  border-color: transparent var(--accent-color) transparent transparent;
}

.chat-message-left {
  background-color: var(--link-color);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  width: 200px;
  font-size: 0.9rem;
  margin-right: auto;
}

.text-balloon-triangle-left {
  width: 0;
  height: 0;
  position: relative;
  right: 10px;
  top: 0px;
  border-style: solid;
  border-width: 20px 40px 0 0;
  border-color: var(--link-color) transparent transparent transparent;
}

a.no-decoration {
  text-decoration: none;
  color: rgb(var(--foreground-rgb));
}

.fake-link {
  color: var(--link-color);
}

.fake-link:hover {  
  text-decoration: underline;
}

.link-color {
  color: var(--link-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.accent-color {
  color: var(--accent-color);
}

.primary-color {
  color: var(--primary-color);
}

.border-link-color {
  border-color: var(--link-color);
}

.border-accent-color {
  border-color: var(--accent-color);
}

.height-screen {
  min-height: calc(100vh - 150px);
}

.background-primary-color {
  background-color: var(--primary-color);
}

.background-link-color {
  background-color: var(--link-color);
}

.no-link-decoration {
  text-decoration: none;
  color: white;
}

.no-link-decoration:hover {
  text-decoration: none;
}

.average-rating {
  background-color: #0076BDBB;
}

#loading-spinner {
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-contents {
  height: calc(100vh - 250px);
  overflow-y: scroll
}

.chat-message {
  height: 100px;
  position: absolute;
  top: -150px;
  left: 0;
  right: 0;
}

.chat-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-close-panel:hover + .chat-close-button {
  opacity: 100;
}

.chat-close-button:hover {
  opacity: 100;
}

.tabbar {
  min-height: 80px;
}

.tabbar__label {
  font-family: 'Red Hat Display', sans-serif !important;
}

.tabbar__item {
  padding: 0;
  margin: 0;
  background-color: var(--accent-color);
  color: green;
}

.tabbar__button {
  margin-top: -15px;
  height: 70px !important;
}

ons-icon {
  color: white;
}

.tabbar__label {
  color: white;
  padding: 5px;
}

ons-segment {
  color: white;
  border: 1px solid var(--custom-input-border-color) !important;
}

.segment__item {
  color: white;
  background-color: var(--link-color);
}

.text-input {
  margin-left: 10px !important;
  padding-left: 10px !important;
  background-color: white !important;
  width: 100% !important;
  border: 1px solid var(--custom-input-border-color) !important;
  border-radius: 10px !important;
}

.search-field {
  width: calc(100% - 20px);
  margin-bottom: 10px;
}

.full-width-important {
  width: calc(100% - 50px) !important;
}

.page__background {
  background-color: white !important;
}

.button {
  background-color: var(--link-color) !important;
  border-radius: 10px !important;
  text-align: center;
}

.button--quiet {
  color: var(--link-color) !important;
  background-color: transparent !important;
  background: transparent !important
}

.search-input {
  background-color: white !important;
  /* border: 1px solid var(--custom-input-border-color) !important; */
  max-height: 28px !important;
  border-radius: 7px !important;
  box-shadow: none !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.list-header {
  /* background-color: var(--custom-input-border-color) !important; */
  max-height: 35px !important;
  font-weight: 500 !important;
}

.search-icons {
  background-color: none !important;
  background: none !important;
  color: var(--secondary-color) !important;
  max-height: 28px !important;
}

.tabbar-icon {
  background-color: none;
  background: none !important;
  color: black !important;
  max-height: 28px !important;
}

.tabbar-icon-camera {
  background-color: var(--link-color) !important;
  background: none !important;
  color: var(--secondary-color) !important;
  max-height: 28px !important;
}

.text-input--material {
  background-image: none !important;
  min-height: 31px;
}

.toolbar {
  background-image: none !important;
}

.toolbar--material {
  box-shadow: none !important;
  background-color: var(--primary-color) !important;
}

.page__background {
  background-color: var(--primary-color) !important;
}

.page__content > .list:not(.list--material):first-child {
  margin-top: 0 !important;
}

.mobile-detail-toolbar {
  border-bottom: 1px solid var(--custom-input-border-color) !important;
}

.toolbar--material__center:last-child {
  margin-right: 0 !important;
} 

.mobile-auction-image {
  border: 3px solid var(--link-color);
}

.mobile-business-image {
  border: 3px solid var(--accent-color);
}

.mobile-my-items {
  min-height: calc(100vh - 150px);
}

.active-tab {
  border-bottom: 5px solid var(--link-color);
}

.map-search {
  height: calc(100% - 38px);
}

textarea {
  border: 1px solid var(--custom-input-border-color) !important;
	border-radius: 7px;
  padding-top: 10px;
  padding-left: 10px;
}

.textarea {
  resize: none;
  height: 200px;
  border: 1px solid var(--custom-input-border-color) !important;
  background-color: white !important;
  width: calc(100% - 20px) !important;
  border-radius: 10px !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.underline-none {
  text-decoration: underline;
  text-decoration-color: white;
}

.mobile-unread-msg-counter {
  color: white;
  background-color: var(--alert-color);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
}

.underline-unread {
  text-decoration: underline;
  text-decoration-color: var(--alert-color);
}

.read-notification {
  color: var(--inactive-icon-color) !important;
}

.unread-notification {
  color: var(--link-color) !important;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.mobile-navigation-button {
  font-size: 21px !important;
}

.list {
  background-color: var(--primary-color) !important;
}

.list-header .body-style {
  color: var(--secondary-color) !important;
}

.tabbar-label-color {
  color: white;
}

.tabbar-background-color {
  background-color: white;
}

.inactive-icon-color {
  color: var(--inactive-icon-color);
}

.custom-input-border-color {
  color: var(--custom-input-border-color);
}

.login-border {
  border: 3px solid white;
  border-radius: 10px;
  background-color: #33192D;
  color: white;
}

.login-field {
  border: 1px solid #dddddd !important;
  border-radius: 5px !important;
  color: var(--search-input-color);
}

.login-color {
  background-color: #533e4f;
}

.mobile-textarea {
  font-size: 0.8rem !important;
  padding-left: 10px !important;
  padding-top: 5px !important;
}

.chat-button-mobile {
  position: absolute;
  bottom: 5px;
  right: 0px;
}

.bid-button-mobile {
  position: absolute;
  bottom: 150px;
  right: 10px;
}

.login-background-image {
  background-image: url("/public/app_background15.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-background-image-web {
  background-image: url("/public/app_background15.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -600px;
}

.mobile-bid-list {
  overflow-y: scroll !important;
  transition: all 200ms ease;
  max-height: 200px;
}

.highest-bid {
  border: 2px solid var(--link-color);
  border-left: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
  margin-left: -20px;
  padding-left: 24px;
}

.your-highest-bid {
  border: 2px solid white;
  border-left: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
  margin-left: -20px;
  padding-left: 24px;
}

.highest-bid-label {
  border-bottom-right-radius: 10px;
  position: relative;
  top: 20px;
  font-family: 'Red Hat Display', sans-serif !important;
}

.your-highest-bid-label {
  color: black;
  background-color: white;
  font-family: 'Red Hat Display', sans-serif !important;
}

.highest-bid-label-corner {
  border-bottom-right-radius: 10px;
}

.highest-bid-label-combined {
  top: 4px !important;
}

.mobile-bid-date-color {
  color: #d6d6d6;
}

.mobile-bid-panel {
  background-color: var(--primary-color);
}

.mobile-top-border {
  border-top: 1px solid #ffffff61;
}

.mobile-avatar-border {
  border: 2px solid var(--link-color);
}

.mobile-avatar-corner-tl {
  border-top-left-radius: 10px !important;
}

.mobile-avatar-corner-tr {
  border-top-right-radius: 10px !important;
}

.new-item-description {
  font-size: 14px !important;
}

.mobile-embedded-border {
  border: 3px solid var(--link-color);
  border-radius: 10px;
  padding: 10px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paginator-button {
  background-color: transparent !important;
  border: 0;
  min-width: 50px;
  font-size: 1.0rem;
  z-index: 1000;
  padding: 10px;
  cursor: pointer;
}

.select-image-grow {
  flex-grow: 1 !important;
}

.selected-avatar-box {
  width: 70px;
  height: 70px;
  position: relative;
  margin-top: -70px;
  left: 0px;
}

.embedded-bid-list {
  overflow-x: scroll;
  max-height: 200px;
}

.web-border-input {
  border: 1px solid #dddddd !important;
}

.loading-full-height {
  height: calc(100vh - 161px);
}

.admin-panel {
  min-width: 150px;
  min-height: 150px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  align-content: center;
}

.full-width-table {
  width: calc(100vw - 200px);
  background-color: white !important;
  padding: 20px;
  border: 3px solid var(--link-color);
  border-radius: 10px;
}

.MuiModalDialog-root {
  position: fixed !important;
  position:fixed;
  top: 0; left: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}

.MuiAccordionSummary-button {
  padding: 0;
  margin: 0;
}

.MuiSelect-button {
  font-family: 'Red Hat Display', sans-serif !important;
}


.MuiCheckbox-input {
  background-color: var(--link-color) !important;
}

.MuiSlider-valueLabel {
  background-color: var(--link-color) !important;
  color: white !important;
}

.MuiSlider-track {
  background-color: var(--link-color) !important;
}

.MuiOutlinedInput-root {
  height: 35px !important;
}

.MuiOutlinedInput-input {
  height: 0 !important;
}

.MuiRadio-label {
  font-family: 'Red Hat Display', sans-serif !important;
  font-weight: 400 !important;
}

.MuiTableContainer-root {
  background-color: transparent !important;
  box-shadow: none !important;
}

.MuiTableCell-root {
  color: black !important;
  font-family: 'Red Hat Display', sans-serif !important;
  border-bottom: 1px solid #bbbbbb !important;
}

.MuiTablePagination-displayedRows {
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiTablePagination-selectLabel {
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiSelect-select {
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiBox-root {
  background-color: transparent !important;
}

.MuiBox-root > span {
  color: white !important;
}

.MuiTableCell-root > .MuiButtonBase-root {
  color: white !important;
}

.MuiTableRow-root > .MuiTableCell-root {
  color: white !important;
  border-bottom: none !important;
}

.MuiAlert-root > .MuiAlert-message {
  background-color: transparent !important;
}

.MuiButton-contained {
  font-family: 'Red Hat Display', sans-serif !important;
  text-transform: none !important;
}

.MuiFormLabel-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiSelect-select {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

li > .MuiPaginationItem-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiSvgIcon-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid yellow !important;
}

.MuiButtonBase-root > .MuiSvgIcon-root {
  color: white !important;
}

.MuiTableSortLabel-root,.MuiTableSortLabel-icon {
  color: var(--link-color) !important;
}

.MuiSlider-track {
  background-color: var(--link-color);
}

.MuiSlider-valueLabel {
  background-color: var(--link-color);
}

.MuiSlider-thumb {
  border-color: var(--link-color);
  color: var(--link-color);
}

.MuiRadio-input {
  color: var(--link-color);
}

.MuiAlert-colorInfo {
  background-color: transparent !important;
}

.MuiStack-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiButton-textPrimary {
  text-transform: none !important;
}

.MuiTableSortLabel-icon {
  fill: var(--link-color) !important;
}

.MuiBox-root {
  color: white !important;
}

.MuiListItemIcon-root {
  color: white !important;
}

.MuiInput-input {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiFormHelperText-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiInput-underline::before {
  border-bottom: 1px solid white !important;
}

.MuiButton-textPrimary {
  font-family: 'Red Hat Display', sans-serif !important;
  text-transform: none;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiMenuItem-root {
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiMenuItem-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.MuiButton-root.Mui-disabled {
  color: var(--accent-color) !important;
}

.MuiInput-input {
  font-size: 14px !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

ul > .MuiButtonBase-root {
  color: black !important;
  font-size: 14px;
}

ul > .MuiMenuItem-root {
  color: white !important;
  font-family: 'Red Hat Display', sans-serif !important;
}

.list-header > div {
  font-size: 14px;
}

.gm-style-iw-chr {
  height: 20px;
}

.mobile-tabbar-style {
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: 1rem;
  z-index: 1000; 
  padding-top: 10px;
  margin-top: 20px;
  height: 70px;
}

.mobile-tabbar-camera-style {
  position: relative;
  top: -4px;
}

.stripe-example-border {
  border: 2px solid var(--link-color);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 1.0);
}
